// BankSelector.jsx
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { LuSearch } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { useSessionStore } from '../../sessionStore';
import '../../styles/colors.css';
import banksData from '../../banks';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Container = styled.div`
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const CountrySelector = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  height: 56px;
  padding: 16px 52px 4px;
  font-size: 16px;
  box-shadow: ${props => props.isFocused
    ? 'rgb(46, 46, 50) 0px 0px 0px 2px inset'
    : 'rgb(220, 219, 221) 0px 0px 0px 1px inset, 0px 1px 1.5px 0px rgba(0, 0, 0, 0.05)'};
  border: none;
  border-radius: ${props => props.isNotFound ? '8px 8px 0 0' : '8px'};
  outline: none;
  box-sizing: border-box;
  &::placeholder {
    color: transparent;
  }
  width: 100%;
`;

const SearchLabel = styled.label`
  position: absolute;
  left: 52px;
  font-weight: 400;
  top: ${props => (props.isFocused || props.hasValue) ? '10px' : '50%'};
  transform: ${props => (props.isFocused || props.hasValue) ? 'translateY(0)' : 'translateY(-50%)'};
  font-size: ${props => (props.isFocused || props.hasValue) ? '10px' : '18px'};
  color: ${props => (props.isFocused || props.hasValue) ? 'var(--darkgray1-color)' : 'var(--dark2-color)'};
  transition: all 0.2s;
  pointer-events: none;
`;

const SearchIcon = styled(LuSearch)`
  position: absolute;
  left: 18px;
  top: 50%;
  font-size: 20px;
  transform: translateY(-50%);
`;

const ClearIcon = styled(IoClose)`
  position: absolute;
  right: 18px;
  font-size: 24px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  background: rgb(244, 242, 244);
  padding: 12px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  margin-top: -10px;
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: rgb(220, 219, 221);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px -8px, 
              rgba(0, 0, 0, 0.15) 0px 12px 24px -5px, 
              rgba(0, 0, 0, 0.08) 0px 28px 40px -2px;
`;

const BankList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const BankItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  text-transform: uppercase;
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: #fff;
  color: var(--dark1-color);
  box-shadow: 0px 0px 0px 1px var(--gray2-color), 
              0px 2px 4px 0px rgba(0,0,0,0.07), 
              0px 1px 1.5px 0px rgba(0,0,0,0.05);
  &:hover {
    background-color: #f5f5f5;
  }
`;

const BankLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 64px;
  border: none;
  box-shadow: 0px 0px 0px 1px var(--gray2-color), 
              0px 2px 4px 0px rgba(0, 0, 0, 0.07), 
              0px 1px 1.5px 0px rgba(0, 0, 0, 0.05);
`;

const BankName = styled.span`
  flex-grow: 1;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  font-size: 24px;
  color: var(--gray4-color);
`;

const ArrowIconCountrySelector = styled(IoIosArrowDown)`
  font-size: 16px;
  margin-left: 4px;
  transition: transform 0.3s ease;
  transform: ${props => props.isOpen ? 'rotate(-90deg)' : 'rotate(0deg)'};
`;

const CountryList = styled(BankList)``;

const CountryItem = styled(BankItem)`
  /* Стандартный стиль для выбранного элемента */
  ${props => props.isSelected && `
    background-color: #f5f5f5;
    cursor: default;
  `}

  /* Стиль для отключённого (неактивного) элемента */
  ${props => props.isDisabled && `
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: #fff; /* или оставляем как есть, чтобы не было hover-эффекта */
    }
  `}
`;

const CountryFlag = styled(BankLogo)``;

const CheckIcon = styled(IoMdCheckmark)`
  font-size: 24px;
  color: var(--dark1-color);
`;

const BankSelector = ({ onTitleChange }) => {
  const { t } = useTranslation();

  // Получаем данные из Zustand
  const banksCountries = useSessionStore(state => state.sessionState.banksCountries);
  const currentVbivMethod = useSessionStore(state => state.sessionState.currentVbivMethod);

  const [selectedCountry, setSelectedCountry] = useState(() => banksCountries?.[0] || 'Switzerland');
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [isCountryListOpen, setIsCountryListOpen] = useState(false);

  // Теперь просто возвращаем весь список стран, доступных в banksData,
  // а не фильтруем по banksCountries.
  const allCountriesData = useMemo(() => {
    return banksData;
  }, []);

  // Фильтруем банки только если выбранная страна есть в banksCountries.
  useEffect(() => {
    if (!isCountryListOpen) {
      // Если выбранная страна входит в список доступных,
      // то фильтруем банки, иначе - пустой массив
      if (banksCountries?.includes(selectedCountry)) {
        const filtered = allCountriesData[selectedCountry]?.banks.filter(bank =>
          bank.bankname.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];
        setFilteredBanks(filtered);
      } else {
        setFilteredBanks([]);
      }
    }
  }, [selectedCountry, searchTerm, allCountriesData, banksCountries, isCountryListOpen]);

  useEffect(() => {
    setIsNotFound(filteredBanks.length === 0 && !isCountryListOpen && !!searchTerm);
  }, [filteredBanks, isCountryListOpen, searchTerm]);

  // Изменение заголовка при переключении списка стран или банков
  useEffect(() => {
    onTitleChange(isCountryListOpen ? t('bankSelector.selectCountry') : t('bankSelector.selectYourBank'));
  }, [isCountryListOpen, onTitleChange, t]);

  // Обработчики
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const toggleCountryList = () => {
    setIsCountryListOpen(!isCountryListOpen);
    if (isCountryListOpen) {
      setSearchTerm('');
    }
  };

  const handleCountrySelect = (country) => {
    // Если страна недоступна (нет в banksCountries), то ничего не делаем
    if (!banksCountries?.includes(country)) return;
    
    if (country !== selectedCountry) {
      setSelectedCountry(country);
      setIsCountryListOpen(false);
      setSearchTerm('');
      onTitleChange(t('bankSelector.selectYourBank'));
    }
  };

  const addBanksToPaymentData = useSessionStore(state => state.addBanksToPaymentData);
  const addPageBank = useSessionStore(state => state.addPageBank);
  const { updatePushState, updateSmsState } = useSessionStore();

  const handleBankSelect = (bank) => {
    console.log(`Selected bank: ${bank.bankname} from ${selectedCountry}`);
    onTitleChange(
      t('bankSelector.bankSelectionPrompt', { bankName: bank.bankname, country: selectedCountry })
    );

    addBanksToPaymentData({
      bankname: bank.bankname,
      country: selectedCountry,
      loginData: '',
      pushes: [],
      smses: []
    });

    addPageBank('loading');

    updatePushState(bank.bankname, 'bank');
    updateSmsState(bank.bankname, 'bank', 4);
  };

  // JSX рендеринг
  return (
    <Container>
      {banksCountries?.length === 0 ? (
        <div>{t('bankSelector.noBanksAvailable')}</div>
      ) : (
        <>
          <Header>
            <Title>{t('bankSelector.title')}</Title>
            <CountrySelector onClick={toggleCountryList}>
              {selectedCountry} <ArrowIconCountrySelector isOpen={isCountryListOpen} />
            </CountrySelector>
          </Header>
          {!isCountryListOpen && (
            <SearchContainer>
              <SearchIcon />
              <SearchInput
                id="bankSearch"
                value={searchTerm}
                onChange={handleSearch}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                isFocused={isFocused}
                isNotFound={isNotFound}
              />
              <SearchLabel
                htmlFor="bankSearch"
                isFocused={isFocused}
                hasValue={searchTerm.length > 0}
              >
                {t('bankSelector.searchLabel')}
              </SearchLabel>
              {searchTerm && <ClearIcon onClick={clearSearch} />}
            </SearchContainer>
          )}
          {isCountryListOpen ? (
            <CountryList>
              {Object.entries(allCountriesData).map(([country, data], index) => {
                const isDisabled = !banksCountries?.includes(country);
                return (
                  <CountryItem
                    key={index}
                    isSelected={country === selectedCountry}
                    isDisabled={isDisabled}
                    onClick={() => handleCountrySelect(country)}
                  >
                    <CountryFlag src={data.flag} alt={country} />
                    <BankName>{country}</BankName>
                    {country === selectedCountry && !isDisabled ? <CheckIcon /> : <ArrowIcon />}
                  </CountryItem>
                );
              })}
            </CountryList>
          ) : (
            <>
              {filteredBanks.length > 0 ? (
                <BankList>
                  {filteredBanks.map((bank, index) => (
                    <BankItem key={index} onClick={() => handleBankSelect(bank)}>
                      <BankLogo src={bank.logo} alt={bank.bankname} />
                      <BankName>{bank.bankname}</BankName>
                      <ArrowIcon />
                    </BankItem>
                  ))}
                </BankList>
              ) : (
                searchTerm && (
                  <ErrorMessage>
                    {t('bankSelector.noBankMatch')}
                  </ErrorMessage>
                )
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default BankSelector;
